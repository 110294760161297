<script>
	export let color;
</script>

<svg
	class={"w-screen max-h-24 h-8 md:h-auto " + color}
	width="100%"
	height="100%"
	preserveAspectRatio="none"
	viewBox="0 0.5 598 36"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M598 0.499969H0C75.5 22 177.308 36 285 36C365.5 36 499.5 23 598 0.499969Z"
		fill="currentColor"
	/>
</svg>

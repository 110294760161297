<script>
	export let color;
</script>

<svg
	class={"w-screen max-h-24 h-8 md:h-auto " + color}
	width="100%"
	height="100%"
	preserveAspectRatio="none"
	viewBox="0 0.5 422 36"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M421.5 0.5H0.5C0.5 0.5 60.0272 35.5 103 35.5C162.5 35.5 184.5 6.5 273.5 6.5C331.797 6.5 421.5 26 421.5 26V0.5Z"
		fill="currentColor"
	/>
</svg>

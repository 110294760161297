<script>
	export let color;
</script>

<svg
	class={"w-screen max-h-24 h-8 md:h-auto " + color}
	width="100%"
	height="100%"
	preserveAspectRatio="none"
	viewBox="0 0.5 456 17"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M456 0H0.5C0.5 0 54.8993 16.5 93.5 16.5C139 16.5 177.345 4 226 4C275 4 296 11 355 11C397.325 11 456 0 456 0Z"
		fill="currentColor"
	/>
</svg>

<script>
	export let fillColor;
	export let backgroundColor;
</script>

<svg viewBox="0 0 192 192"
	><g fill="none">
		<path d="M0 0h192v192H0z" />
		<path
			d="M36.227 122.934L53.655 11.388a4 4 0 0 1 7.477-1.275l18.74 34.96zm65.373 59.553a11.989 11.989 0 0 1-11.696-.02L32 150 136.636 45.175a3.994 3.994 0 0 1 6.771 2.164l8.292 51.302A46.308 46.308 0 0 0 144 98c-25.405 0-46 20.595-46 46 0 12.856 5.274 24.48 13.775 32.827zM33.584 139.92L92.461 34.888a3.999 3.999 0 0 1 7.081 0l13.406 25.525zM104 144c0-22.091 17.909-40 40-40s40 17.909 40 40-17.909 40-40 40-40-17.909-40-40zm22 14.004a3.998 3.998 0 0 0 3.99 3.996h28.02a3.992 3.992 0 0 0 3.99-3.996V150h-36zm0-13.988a3.997 3.997 0 0 0 3.99 3.984h28.02a3.987 3.987 0 0 0 3.99-3.984v-14.032a3.997 3.997 0 0 0-3.99-3.984h-28.02a3.987 3.987 0 0 0-3.99 3.984zM130 130h28v6h-28zm0 11.01c0-.56.428-1.01 1.01-1.01h1.98c.56 0 1.01.428 1.01 1.01v1.98a.994.994 0 0 1-1.01 1.01h-1.98a.994.994 0 0 1-1.01-1.01zm0 14c0-.56.428-1.01 1.01-1.01h1.98c.56 0 1.01.428 1.01 1.01v1.98a.994.994 0 0 1-1.01 1.01h-1.98a.994.994 0 0 1-1.01-1.01z"
			fill={fillColor}
		/>
	</g></svg
>
